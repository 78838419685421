<template>
  <div>
    <tabs :tabList="tabList" class="mt-8" @active-tab="activeTabName = $event" />

    <request-logs v-if="activeTabName === 'RequestLogs'" />

    <div
      class="box box--white mt-16"
      v-if="activeTabName === 'CurrencyConverter' && convertCurrency && convertCurrency"
    >
      <p>
        From: <span class="text-bold">{{ convertCurrency.from }}</span>
      </p>
      <p>
        To: <span class="text-bold">{{ convertCurrency.to }}</span>
      </p>
      <p>
        Buy: <span class="text-bold">{{ convertCurrency.buy }}</span>
      </p>
      <p>
        Sell: <span class="text-bold">{{ convertCurrency.sell }}</span>
      </p>

      <p>
        Last updated:
        <span class="text-bold">{{ convertCurrency.lastUpdatedAtMilliseconds | moment_lts }}</span>
      </p>

      <p class="text-error text-bold mt-4" v-if="!isCurrencyConverterAlvie">
        WARNING: Currency Converter may be got an error. Please check!
      </p>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
import RequestLogs from './RequestLogs'
import Tabs from '@/components/Tabs'

export default {
  data() {
    return {
      activeTabName: 'RequestLogs',
      tabList: [
        { id: 3, name: 'RequestLogs', title: 'Request Logs' },
        { id: 2, name: 'CurrencyConverter', title: 'Currency Converter' }
      ]
    }
  },

  mixins: [toggleLoading],

  components: {
    Tabs,
    RequestLogs
  },

  computed: {
    ...mapState('setting', ['convertCurrency']),

    now() {
      return new Date().getTime()
    },

    isCurrencyConverterAlvie() {
      return this.convertCurrency && this.now - this.convertCurrency.lastUpdatedAtMilliseconds < 2 * 60 * 60 * 1000 // 2 hours
    }
  },

  methods: {
    ...mapActions('setting', ['getConvertCurrency']),

    async initData() {
      this.toggleLoading()
      try {
        if (this.activeTabName === 'CurrencyConverter') await this.getConvertCurrency()
      } catch (e) {
        this.toggleLoading()
        throw e
      } finally {
        this.toggleLoading()
      }
    }
  },

  created() {
    this.toggleLoading()
  },

  watch: {
    activeTabName() {
      this.initData()
    }
  }
}
</script>
